<template>
  <v-row>
    <v-col cols="12" sm="6" lg="4">
      <base-material-stats-card
        color="info"
        icon="mdi-briefcase-check"
        title="Candidatures"
        v-model="this.candidatures"
        :href="CandidaturesPage"
      >
      </base-material-stats-card>
    </v-col>

    <v-col cols="12" sm="6" lg="4">
      <base-material-stats-card
        color="info"
        icon="mdi-account-multiple-outline"
        title="Contacts"
        v-model="this.contact"
        :href="ContactPage"
      />
    </v-col>

    <v-col cols="12" sm="6" lg="4">
      <base-material-stats-card
        color="info"
        icon="mdi-account-group-outline"
        title="Utilisateurs"
        v-model="this.users"
        :href="UsersPage"
      />
    </v-col>
  </v-row>
</template>
<script>
import FirebaseDB from "../../../utils/firebaseDB";
import firebase from "firebase";
export default {
  name: "ChartWidgets",

  data() {
    return {
      CandidaturesPage: "/MercaAdmin/Recruitments",
      ContactPage: "/MercaAdmin/Contacts",
      UsersPage: "/MercaAdmin/Users",
      candidatures: null,
      contact: null,
      users: null,
    };
  },

  methods: {
    async updateWidget() {
      var generalStats = await FirebaseDB.getGeneralStats();

      generalStats.forEach((stat) => {
        this.users = stat.data().number_total_users.toString();
        this.contact = stat.data().number_total_contacts.toString();
        this.candidatures = stat.data().number_total_recruitments.toString();
      });
    },
  },
  beforeMount() {
    if (firebase.auth().currentUser) {
      this.isLoggedIn = true;
      this.currentUserDisplayName = firebase.auth().currentUser.displayName;
      this.currentUserEmail = firebase.auth().currentUser.email;

      firebase
        .auth()
        .currentUser.getIdTokenResult()
        .then((tokenResult) => {
          if (tokenResult.claims.admin) {
            this.updateWidget();
          }
        });
    }
  },
};
</script>
